const DEFAULT_LANG = "it";
const DEBUG = false;
const AVAILABLE_LANGS = [
    'en',
    'zh',
    'de',
    'es',
    'ru',
    'fr',
    'it'
]

export function getUserDefaultLang(crm_data: any): string {

    if (DEBUG) console.log("getUserDefaultLang", JSON.stringify(crm_data));

    if (crm_data) {

        let crmDataLang = crm_data?.language;
        let convertedLanguage = null;

        if (crmDataLang) {
            if (crmDataLang.toUpperCase() === "ITALIANO") convertedLanguage = 'it';
            if (crmDataLang.toUpperCase() === "INGLESE") convertedLanguage = 'en';
            if (crmDataLang.toUpperCase() === "TEDESCO") convertedLanguage = 'de';
            if (crmDataLang.toUpperCase() === "FRANCESE") convertedLanguage = 'fr';
            if (crmDataLang.toUpperCase() === "CINESE") convertedLanguage = 'zh';
            if (crmDataLang.toUpperCase() === "SPAGNOLO") convertedLanguage = 'es';
            if (crmDataLang.toUpperCase() === "RUSSO") convertedLanguage = 'ru';
        }

        if (!convertedLanguage) convertedLanguage = DEFAULT_LANG;
        if (DEBUG) console.log("convertedLanguage", convertedLanguage);
        return convertedLanguage;

    } else {

        if (!navigator) {

            if (DEBUG) console.log("No navigator");

            const headers = useRequestHeaders(['accept-language'])
            if (headers['accept-language']) {
                let acceptedLanguages = headers['accept-language'];
                acceptedLanguages = acceptedLanguages?.split(',');
                let acceptedLang = acceptedLanguages[0];
                if (acceptedLang.indexOf("-") >= 0) {
                    acceptedLang = acceptedLang.split("-")[0];
                }

                if (AVAILABLE_LANGS.includes(acceptedLang)) {
                    if (DEBUG) console.log("acceptedLang", acceptedLang);
                    return acceptedLang;
                }
            }
            if (DEBUG) console.log("DEFAULT_LANG", DEFAULT_LANG);
            return DEFAULT_LANG;

        } else {

            if (DEBUG) console.log("Navigator is present");

            let userLang = navigator.language || navigator.userLanguage;
            if (!userLang) {
                if (DEBUG) console.log("DEFAULT_LANG", DEFAULT_LANG);
                return DEFAULT_LANG;
            }

            userLang = userLang.substring(0, 2);

            if (AVAILABLE_LANGS.includes(userLang)) {
                if (DEBUG) console.log("userLang", userLang);
                return userLang;
            }

            if (DEBUG) console.log("Latest DEFAULT_LANG", DEFAULT_LANG);

            return DEFAULT_LANG;

        }

    }

}
