import revive_payload_client_0yXaKhoZOR from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.13.4_better-sqlite3@11.8.1_db0@0.2.1_better-_sqzwyfcer36tthxeg23bwmirsq/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_cgmdVj967X from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.13.4_better-sqlite3@11.8.1_db0@0.2.1_better-_sqzwyfcer36tthxeg23bwmirsq/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_0gJRr5reTi from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.13.4_better-sqlite3@11.8.1_db0@0.2.1_better-_sqzwyfcer36tthxeg23bwmirsq/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_UvCCS4Mmeu from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.13.4_better-sqlite3@11.8.1_db0@0.2.1_better-_sqzwyfcer36tthxeg23bwmirsq/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_oewv1PQ2oA from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.13.4_better-sqlite3@11.8.1_db0@0.2.1_better-_sqzwyfcer36tthxeg23bwmirsq/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_GJ72aisYZP from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.13.4_better-sqlite3@11.8.1_db0@0.2.1_better-_sqzwyfcer36tthxeg23bwmirsq/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_qWkKrq7s2K from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.13.4_better-sqlite3@11.8.1_db0@0.2.1_better-_sqzwyfcer36tthxeg23bwmirsq/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_iuXH69RvBf from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.13.4_better-sqlite3@11.8.1_db0@0.2.1_better-_sqzwyfcer36tthxeg23bwmirsq/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_N7gNWcaJR2 from "/app/node_modules/.pnpm/@storyblok+nuxt@6.2.2_vue@3.5.13_typescript@5.7.3_/node_modules/@storyblok/nuxt/dist/runtime/plugin.js";
import plugin_yVQUygao7n from "/app/node_modules/.pnpm/@nuxt+icon@1.10.3_magicast@0.3.5_rollup@4.30.1_vite@6.1.0_@types+node@22.13.4_jiti@2.4.2_sass_55itqntf2sj6kufp7uqwmwoesq/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_JlVENgDIgx from "/app/node_modules/.pnpm/nuxt-graphql-client@0.2.43_@parcel+watcher@2.5.0_@types+node@22.13.4_enquirer@2.3.6_graphql-t_ghsxzqi7ihcgosy4d4k6p372nq/node_modules/nuxt-graphql-client/dist/runtime/plugin.js";
import session_client_Ae2YQRuMMC from "/app/node_modules/.pnpm/nuxt-auth-utils@0.5.15_magicast@0.3.5/node_modules/nuxt-auth-utils/dist/runtime/app/plugins/session.client.js";
import auth0_ZnwwTmFbx2 from "/app/plugins/auth0.ts";
import translate_56zPzW3R38 from "/app/plugins/translate.ts";
export default [
  revive_payload_client_0yXaKhoZOR,
  unhead_cgmdVj967X,
  router_0gJRr5reTi,
  payload_client_UvCCS4Mmeu,
  navigation_repaint_client_oewv1PQ2oA,
  check_outdated_build_client_GJ72aisYZP,
  chunk_reload_client_qWkKrq7s2K,
  components_plugin_KR1HBZs4kY,
  prefetch_client_iuXH69RvBf,
  plugin_N7gNWcaJR2,
  plugin_yVQUygao7n,
  plugin_JlVENgDIgx,
  session_client_Ae2YQRuMMC,
  auth0_ZnwwTmFbx2,
  translate_56zPzW3R38
]