const shouldShowPrices = "o&Q9Y!LSgGrSpGxL8GktvhMJC3@";
export default function usePermissions() {
    const { user } = useUserSession();

    // 0 EUR
    // 1 GBP
    // 2 CNY
    // 3 RUB
    // 4 USD
    // 5 UK_EURO
    const listino = useCookie("price-list", { default: () => "0" });
    const forcedAccess = useCookie("forcedAccess", { default: () => "" });

    const permissions = {
        has_admin:
            user?.value?.profile?.user_metadata?.crm_data?.has_admin === true,
        has_tech_catalog:
            user?.value?.profile?.user_metadata?.crm_data?.has_tech_catalog ??
            false,
        has_quick_ship:
            user?.value?.profile?.user_metadata?.crm_data?.has_quick_ship ??
            false,
        has_prices:
            (forcedAccess.value == shouldShowPrices ||
                user?.value?.profile?.user_metadata?.crm_data?.has_prices) ??
            false,
        has_prices_cny:
            (forcedAccess.value == shouldShowPrices ||
                user?.value?.profile?.user_metadata?.crm_data
                    ?.has_prices_cny) ??
            false,
        has_prices_gbp:
            (forcedAccess.value == shouldShowPrices ||
                user?.value?.profile?.user_metadata?.crm_data
                    ?.has_prices_gbp) ??
            false,
        has_prices_rub:
            (forcedAccess.value == shouldShowPrices ||
                user?.value?.profile?.user_metadata?.crm_data
                    ?.has_prices_rub) ??
            false,
        has_prices_usd:
            (forcedAccess.value == shouldShowPrices ||
                user?.value?.profile?.user_metadata?.crm_data
                    ?.has_prices_usd) ??
            false,
        has_selection_access: ["INTERNI", "AGENTI"].includes(
            user?.value?.profile?.user_metadata?.crm_data?.type
        ),
        // has_selection_access:
        //     user?.value?.profile?.user_metadata?.crm_data?.preview ?? false,
        // has_selection_access: [
        //     "elena.cardillo@baxter.it",
        //     "daniele.vanetti@baxter.it",
        //     "monica.bassani@baxter.it",
        //     "nicole.dellavalle@baxter.it",
        //     "silvia.nava@baxter.it",
        //     "augusto.regazzoni@baxter.it",
        //     "margaret.kasynets@baxter.it",
        //     "fabio.trezzi@gmail.com",
        //     "francesca.molteni@baxter.it",
        // ].includes(user?.value?.email),
    };

    function checkListino(value: string) {
        let isValid = true;

        if (["0", "1", "2", "3", "4"].includes(`${value}`) == false) {
            isValid = false;
        } else if (value == "0" && permissions.has_prices == false) {
            isValid = false;
        } else if (value == "1" && permissions.has_prices_gbp == false) {
            isValid = false;
        } else if (value == "2" && permissions.has_prices_cny == false) {
            isValid = false;
        } else if (value == "3" && permissions.has_prices_rub == false) {
            isValid = false;
        } else if (value == "4" && permissions.has_prices_usd == false) {
            isValid = false;
        }

        if (isValid == false) {
            if (permissions.has_prices) {
                return (listino.value = "0");
            }
            if (permissions.has_prices_gbp) {
                return (listino.value = "1");
            }
            if (permissions.has_prices_cny) {
                return (listino.value = "2");
            }
            if (permissions.has_prices_rub) {
                return (listino.value = "3");
            }
            if (permissions.has_prices_usd) {
                return (listino.value = "4");
            }
            return (listino.value = "");
        }
    }

    checkListino(listino.value);
    watch(listino, (v) => checkListino(v));

    return {
        listino,
        ...permissions,
        forcedAccess,
    };
}
